import React from "react"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import LogoSVG from "../../assets/svg/redandblack/logo.svg"

import "./template-styles.scss"
import "./red-and-black.scss"
import Img from "../../assets/images/redandblack-images"

import logoVideo from "../../assets/images/redandblack/RB14.mp4"
import desktopVideo from "../../assets/images/redandblack/RB11.mp4"
import useScreenTranslate from "../../hooks/useScreenTranslate"

import SEO from "../../components/seo"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import TransitionLink from "../../components/TransitionLink"
import NavigationOrbs from "../../components/NavigationOrbs"

const black = "#212931"
const white = "#ffffff"
const red = "#DE5034"

const NewTemplate = () => {
  ProjectAnimations()

  const {
    scrollerSectionRef,
    scrollerScreenContainerRef,
    scrollerScreenRef,
    screenTranslate,
  } = useScreenTranslate({ durationInScreens: 3 })

  return (
    <div className="project red-and-black">
      <SEO
        title="Red and Black"
        description="A rebrand for the architectural construction company behind head-turning builds. Strategy, brand identity, tone of voice, key messaging and content, photography, website, collateral."
      />
      <NavigationOrbs />

      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="rb1" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: red, color: white }}
        >
          <div>
            <h1>Red + Black</h1>
            <p>
              Some construction companies build. Red + Black Construction craft.
              They’re award-winners whose work includes some of the regions most
              inspiring, architecturally-designed homes and favourite commercial
              projects and builds. We built their brand platform accordingly.
              <br />
              <br />
              Strategy, brand identity, tone of voice, key messaging and
              content, photography, website, collateral.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-1">
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--centered"
          style={{ backgroundColor: red }}
        >
          <LogoSVG />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: white, color: black }}
        >
          <div>
            <p>
              To craft is to care. A simple, clean, linear architectural look
              and feel reflects the nature of Red + Black’s work and the focus
              that goes into every project.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-2">
        <Spacer />
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="rb2" className="h-full" />
        </div>
      </Slide>

      <Slide className="section-3">
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--centered overflow-hidden"
          style={{ backgroundColor: white }}
        >
          <video src={logoVideo} autoPlay playsInline muted loop></video>
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: red, color: white }}
        >
          <div>
            <p>
              The logomark builds itself from an R and B informed by building
              plans. Rather than stating the obvious with the colourway, the
              palette is vibrant, light and simple.{" "}
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-4 overflow-hidden">
        <div
          className="ipad panel--w-full panel--h-screen screen-container"
          style={{ backgroundColor: white }}
        >
          <div className="screen-container__inner-container">
            <Img src="rb3" className="screen-container__frame" />
            <div className="screen-container__screen">
              <video src={desktopVideo} autoPlay playsInline muted loop></video>
            </div>
          </div>
        </div>
      </Slide>

      <div className="section-5">
        <div className="panel--w-full pin-once-scrolled">
          <div
            className="panel--w-half panel--h-screen centered-content "
            style={{ backgroundColor: red, color: white }}
          >
            <div className="panel--text">
              <p>
                Red + Black aren’t about building structures. They’re about
                building futures. The bespoke, interactive website was designed
                to do their ideology justice with a look and feel like a top
                shelf magazine. It's linear, like the logo, with a user
                experience that tells the story of craft and care. High quality
                photos, simple scroll, engaging content. Like flicking through a
                magazine.
                <br />
                <br />
                Experience the full site{" "}
                <a
                  href="https://redblackconstruction.co.nz/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold underline"
                >
                  here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div ref={scrollerSectionRef}>
        <Slide className="section-5a">
          <Spacer />
          <div className="ipad panel--w-half panel--h-screen screen-container bg-white">
            <div className="screen-container__inner-container">
              <Img src="rb4" className="screen-container__frame" />
              <div
                className="screen-container__screen "
                ref={scrollerScreenContainerRef}
              >
                <div
                  ref={scrollerScreenRef}
                  className="screen-container__translate"
                  style={{ transform: `translateY(${screenTranslate}%)` }}
                >
                  <Img src="rb5" className="screen-container__frame" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>

      <Slide className="section-6">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb6" />
        </div>
      </Slide>
      <Slide className="section-7">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb1" />
        </div>
      </Slide>

      <Slide className="section-8">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: white, color: black }}
        >
          <div>
            <p>
              On-brand presentation folders and brochures showcase residential
              and commercial project work.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-9">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb7" />
        </div>
      </Slide>
      <Slide className="section-10">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb11" />
        </div>
      </Slide>
      <Slide className="section-11">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb10" />
        </div>
      </Slide>

      <Slide className="section-12">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb9" />
        </div>
      </Slide>

      <div className="section-13 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: red, color: white }}
        >
          <div className="panel--text">
            <p>
              Clean lines. Quality stock. Well crafted. Sharp business cards
              deliver desirable first impressions.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-14">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb12" />
        </div>
      </Slide>
      <Slide className="section-15">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb2" />
        </div>
      </Slide>
      <Slide className="section-16">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="rb13" />
        </div>
      </Slide>
      <Slide className="section-17">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: red, color: white }}
        >
          <div>
            <p>
              Red + Black Construction were already stand-outs in terms of care,
              capability and delivery. But it’s a tough industry with heavy
              competition. Now, with a sharp brand, they stand out.
              <br />
              <br />
              <TransitionLink
                to="/projects/southern-creations/"
                color="#516046"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default NewTemplate
